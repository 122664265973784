/**
 * General error-handling functionality.
 */

export type Messageable = string | Error | DOMException | ErrorEvent;

export function isMessageable(e: unknown): e is Messageable {
    return (
        typeof e === "string"
        || e instanceof Error
        || e instanceof DOMException
        || e instanceof ErrorEvent
    );
}

export function message(e?: Messageable) {
    if (!e) {
        return "Unspecified error";
    }
    if (typeof e === "string") {
        return e;
    }
    const msg = e.message;
    const name = ("name" in e && e.name) || undefined;
    return name && msg ? `${name}: ${msg}` : name ? String(name) : msg ? String(msg) : String(e);
}
