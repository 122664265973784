import Bugsnag = require("Everlaw/Bugsnag");
import Dom = require("Everlaw/Dom");
import Err = require("Everlaw/Core/Err");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import Str = require("Everlaw/Core/Str");
import Tooltip = require("Everlaw/UI/Tooltip");
import UI = require("Everlaw/UI");
import Widget = require("Everlaw/UI/Widget");
import dojo_keys = require("dojo/keys");
import eventUtil = require("dojo/_base/event");
import { clsx } from "clsx";
import { TextInputAutoComplete } from "design-system";
import { IconButton } from "Everlaw/UI/Button";

/**
 * This is a widget that wraps an input element and includes a label connected to this
 * input element. For accessibility, users of this class should use the label element
 * instead of creating their own divs.
 */
class TextBox
    extends FocusContainerWidget
    implements Widget.WithSettableValue, UI.WidgetWithTextBox
{
    escapeClears: boolean;
    private labelNode: HTMLElement;
    trimTrailingWhitespace = false;
    icon: Icon | IconButton;
    private noSmartQuoteReplace: boolean | undefined;
    private labelPosition: TextBox.LabelPosition;
    onSubmit(val: string, withShiftKey?: boolean) {}
    // Handle every key press in the input box. Return false to stop the key press.
    onKeyDown(k: number, evt: KeyboardEvent) {
        return true;
    }
    onKeyUp(evt: KeyboardEvent) {}
    onChange(val: string) {}
    // called when the clear button is pressed
    onClear() {}
    onPaste(evt: ClipboardEvent) {}
    private tb: HTMLInputElement | HTMLTextAreaElement;
    private iconContainer: HTMLElement;
    private disabledReasonTooltip: Tooltip;
    private clearMark = false;
    private programmedValue: string | null = null;

    constructor(params: TextBox.Params = {}) {
        super(
            Dom.div({
                class: clsx("textbox-container", params.textBoxClass),
                tabindex: params.tabIndex,
            }),
        );
        Dom.place(
            (this.labelNode = Dom.label(
                {
                    class: "textbox-label-content " + (params.labelClass || ""),
                },
                "",
            )),
            this.node,
        );
        this.setTextBoxLabelContent(params.textBoxLabelContent || "");
        this.setTextBoxLabelPosition(params.textBoxLabelPosition || "before");
        const inputParams: any = {
            placeholder: params.placeholder || "",
            name: params.inputName || "",
            style: params.inputStyle,
            class: "textbox-input " + (params.inputClass || ""),
            type: params.type || "text",
        };
        if (Is.boolean(params.spellcheck)) {
            inputParams["spellcheck"] = params.spellcheck;
        }
        params.preventBrowserAutocomplete ??= true;
        if (params.preventBrowserAutocomplete) {
            inputParams.autocomplete = TextInputAutoComplete.OFF.value();
        }

        const inputOrTextAreaFn = params.isTextArea ? Dom.textarea : Dom.input;
        if (params.textBoxContainer) {
            Dom.place(params.textBoxContainer, this.node);
            this.tb = Dom.place(inputOrTextAreaFn(inputParams), params.textBoxContainer);
        } else {
            this.tb = Dom.place(inputOrTextAreaFn(inputParams), this.node);
        }
        this.tb.setAttribute("id", "textbox-" + this.id);
        if (params.textBoxAriaLabel) {
            this.setTextBoxAriaLabel(params.textBoxAriaLabel);
        }
        this.labelNode.setAttribute("for", this.tb.id);
        if (params.width) {
            this.tb.style.width = params.width;
        }
        if (params.focusOnTap && params.placeholder) {
            this.connect(this.tb, Input.tap, (event) => {
                this.tb.focus();
                if (params.onTapStopPropagation) {
                    event.stopPropagation();
                }
                return true;
            });
        }
        this.connect(this.tb, "input", this.onInput.bind(this));
        // The following type casts are not ideal, but the types for dojo's event listeners are not
        // smart enough to know that these listeners will only receive KeyboardEvents.
        this.connect(this.tb, "keydown", this._onKeyDown.bind(this) as (e: Event) => void);
        this.connect(this.tb, "keyup", this._onKeyUp.bind(this) as (e: Event) => void);
        this.connect(this.tb, "paste", this._onPaste.bind(this) as (e: Event) => void);
        this.escapeClears = !!params.escapeClears;
        if (params.clearMark) {
            this.addIcon(
                new IconButton({
                    iconClass: "x-20",
                    ariaLabel: "Clear",
                    onClick: () => {
                        this._clear();
                        params.clearMarkOnClick && params.clearMarkOnClick();
                    },
                }),
            );
            this.clearMark = true;
            Dom.hide(this.iconContainer);
        } else if (params.icon) {
            this.addIcon(params.icon, params.iconPosition);
        }
        if (params.disabledReason) {
            this.disabledReasonTooltip = new Tooltip(this.node, params.disabledReason);
            // disable tooltip because widget is not disabled initially
            this.disabledReasonTooltip.disabled = true;
            this.registerDestroyable(this.disabledReasonTooltip);
        }
        this.trimTrailingWhitespace = params.trimTrailingWhitespace || false;
        this.noSmartQuoteReplace = params.noSmartQuoteReplace;
        this.setValue(params.value || "");
        if (params.onChange) {
            this.onChange = params.onChange;
        }
        this.onBlur = params.onBlur ?? this.onBlur;
        this.onFocus = params.onFocus ?? this.onFocus;
        this.onSubmit = params.onSubmit ?? this.onSubmit;
        this.onKeyDown = params.onKeyDown ?? this.onKeyDown;
    }

    /**
     * Adds an icon or icon button to this textbox. If an icon or clearMark has already been added,
     * this will do nothing.
     */
    private addIcon(icon: Icon | IconButton, position: string = "after") {
        if (this.clearMark || this.icon) {
            return;
        }
        Dom.addClass(this.node, "textbox-with-icon-" + position);
        this.iconContainer = icon.node;
        Dom.place(this.iconContainer, this.node);
        this.registerDestroyable(icon);
        this.icon = icon;
    }
    private _clear() {
        this.focus();
        this.clear();
        this.onClear();
        // We call the onChange callback manually here since setting the value won't trigger
        // the callback below.
        this.onChange("");
        if (this.clearMark) {
            Dom.hide(this.iconContainer);
        }
    }
    private _onPaste(evt: ClipboardEvent) {
        this.onPaste(evt);
    }
    private _onKeyDown(evt: KeyboardEvent) {
        if (evt.keyCode === dojo_keys.ENTER) {
            eventUtil.stop(evt);
            this.onSubmit(this.getValue(), evt.shiftKey);
        } else if (evt.keyCode === dojo_keys.ESCAPE) {
            eventUtil.stop(evt);
            if (this.escapeClears && Str.nonempty(this.value())) {
                this._clear();
            } else {
                this.blur();
            }
        }
        // Always pass the key presses (even enter and escape) to our callback.
        if (!this.onKeyDown(evt.keyCode, evt)) {
            eventUtil.stop(evt);
        }
    }
    private _onKeyUp(evt: KeyboardEvent) {
        this.onKeyUp(evt);
    }
    private onInput(evt: Event) {
        const raw = this.value();

        // Unlike other browsers, IE fires an input event when the value is set programmatically.
        // To make sure our behavior is consistent across browsers, we exit early if the current
        // value matches the last argument to setValue().
        if (raw === this.programmedValue) {
            this.programmedValue = null;
            return;
        }
        this.programmedValue = null;

        if (this.clearMark) {
            Dom.show(this.iconContainer, !!raw);
        }
        this.onChange(raw);
    }
    getValue() {
        const val = this.trimTrailingWhitespace ? this.value().replace(/\s+$/g, "") : this.value();
        // Smart quotes cause problems in Lucene because they don't behave like real quotes.
        return this.noSmartQuoteReplace ? val : Str.replaceSmartQuotes(val);
    }
    setValue(val: string, silent = true) {
        val = this.trimTrailingWhitespace ? val.replace(/\s+$/g, "") : val;
        this.tb.value = val;

        // Unlike other browsers, IE fires an input event when the value is set programmatically.
        // We save the value here so we can ignore the spurious call to onInput.
        this.programmedValue = val;

        if (this.clearMark) {
            Dom.show(this.iconContainer, !!val);
        }

        if (!silent) {
            this.onChange(this.value());
        }
    }
    // Use to be avoided if possible: devs should seek to design the DOM correctly the first time
    getLabel() {
        return this.labelNode;
    }
    getInput(): HTMLInputElement | HTMLTextAreaElement {
        return this.tb;
    }
    setTextBoxAriaLabel(ariaLabel: string) {
        Dom.setAriaLabel(this.tb, ariaLabel);
    }
    setTextBoxLabelContent(labelContent: Dom.Content) {
        Dom.setContent(this.labelNode, labelContent);
        Dom.show(this.labelNode, !!labelContent);
    }
    setTextBoxLabelPosition(position: TextBox.LabelPosition) {
        if (this.labelPosition) {
            Dom.removeClass(this.node, "textbox-container__" + this.labelPosition);
        }
        Dom.addClass(this.node, "textbox-container__" + position);
        this.labelPosition = position;
    }
    moveCursor(pos = 0) {
        this.tb.setSelectionRange(pos, pos);
    }
    setSelected() {
        this.tb.select();
    }
    clear() {
        this.setValue("");
    }
    /**
     * Selects the text of the TextBox. Both parameters are optional, with negative values being
     * offest from the end of the value string.
     * @param begin     the index at which to begin the selection, defaulting to 0
     * @param end       the index at which to end the selection (exclusive), defaulting to the
     *                      length of the value
     * @param _retry    internal use only
     */
    select(begin = 0, end = this.value().length, _retry = true) {
        if (begin < 0) {
            begin = this.value().length + begin;
        }
        if (end < 0) {
            end = this.value().length + end;
        }
        try {
            // In Chrome, the default selection direction seems to be "backward", showing from the
            // start of the text rather than from the end. In firefox, this behavior is opposite,
            // showing the text from the end, so the beginning of the text gets truncated in small
            // width textbox. We force consistent behavior across browsers, defaulting to chrome.
            this.tb.setSelectionRange(begin, end, "backward");
        } catch (e) {
            // In IE/Edge, this operation seems to fail when "attempting to modify the selection
            // during a mouseup/blur/focus event when IE was preparing to update the selection
            // itself." (https://github.com/timdown/rangy/issues/260)
            //
            // When that happens, the error message contains the following unique string. We retry
            // once on the next tick. If it fails again, we just give up quietly since this is a
            // known issue.
            //
            // We've seen a related error when the element is hidden, and we handle that similarly.
            if (Err.isMessageable(e) && /800a025e|Unspecified error/.test(Err.message(e))) {
                if (_retry) {
                    setTimeout(() => {
                        this.select(begin, end, false);
                    });
                } // otherwise, give up quietly
            } else {
                // We're not familiar with this bug, so we'll report it.
                Bugsnag.notifyCustomName("Failed to setSelectionRange", e as Error);
            }
        }
    }
    override focus() {
        this.tb.focus();
    }
    override blur() {
        this.tb.blur();
    }

    setDisabled(isDisabled: boolean) {
        UI.toggleDisabled(this.tb, isDisabled);
        if (this.disabledReasonTooltip) {
            this.disabledReasonTooltip.disabled = !isDisabled;
        }
    }
    setWidth(width: string) {
        Dom.style(this.tb, "width", width);
    }
    setPlaceholder(ph: string) {
        Dom.setAttr(this.tb, "placeholder", ph || "");
    }
    setEllipsizeText(value: boolean) {
        Dom.style(this.tb, "textOverflow", value ? "ellipsis" : "");
    }
    /**
     * Inserts val into the box at the current cursor position, replacing any selected content and
     * placing the cursor after val.
     */
    replaceSelection(val: string) {
        const before = this.value().slice(0, this.tb.selectionStart ?? undefined);
        const after = this.value().slice(this.tb.selectionEnd ?? undefined);
        this.setValue(before + val + after);
        const pos = before.length + val.length;
        this.select(pos, pos);
    }
    toggleErrorOutline(isInvalid: boolean) {
        Dom.toggleClass(this.tb, "error-outline", isInvalid);
        Dom.setAttr(this.tb, "aria-invalid", isInvalid ? "true" : "false");
    }
    toggleIcon(show: boolean) {
        Dom.show(this.icon, show);
    }
    private value() {
        return this.tb.value;
    }
}

module TextBox {
    export interface Params extends UI.WidgetWithTextBoxParams {
        placeholder?: string;
        preventBrowserAutocomplete?: boolean;
        // This is a fix for some textboxes that don't clear their placeholder text correctly in
        // IE11 and Windows7.  This bug tends to happen in DnD sources/containers.
        focusOnTap?: boolean;
        escapeClears?: boolean;
        // clearMark=true adds an X to clear the box when it has content.
        // Only one of (clearMark, !! icon) should be true!
        clearMark?: boolean;
        clearMarkOnClick?: () => void;
        // An icon or icon button to show (always) in the box.
        icon?: Icon | IconButton;
        // Which side of the box to position the icon on ('before' or 'after'). Defaults to 'after'.
        iconPosition?: string;
        flex?: boolean;
        width?: string;
        inputName?: string;
        inputClass?: string;
        inputStyle?: string | Dom.StyleProps;
        tabIndex?: number;
        // By default, this is "text"
        type?: string;
        value?: string;
        trimTrailingWhitespace?: boolean;
        noSmartQuoteReplace?: boolean;
        labelClass?: string;
        onTapStopPropagation?: boolean;
        onChange?: (val: string) => void;
        textBoxContainer?: HTMLElement;
        spellcheck?: boolean;
        textBoxClass?: string;
        // if non-empty, will initialize a tooltip and show this message when the textbox is disabled
        disabledReason?: string;
        isTextArea?: boolean;
        onBlur?: () => void;
        onFocus?: () => void;
        onSubmit?: (val: string, withShiftKey?: boolean) => void;
        onKeyDown?: (k: number, evt: KeyboardEvent) => boolean;
    }

    export type LabelPosition = "before" | "after" | "above";
}

export = TextBox;
