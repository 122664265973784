import Widget = require("Everlaw/UI/Widget");

/**
 * Use this class for widgets that contain multiple child nodes that could have focus. It only
 * issues an onBlur call for the parent widget if focus did not move from one child input to the
 * next (i.e. only if focus really left the parent widget).
 */
class FocusContainerWidget extends Widget {
    protected focusContainer: Widget.DijitFocusContainer;
    constructor(node: HTMLElement) {
        super();
        this.node = node;
        this.registerDestroyable(
            (this.focusContainer = new Widget.DijitFocusContainer({
                domNode: this.node,
                onBlur: () => {
                    this._onBlur();
                },
                onFocus: () => {
                    this._onFocus();
                },
            })),
        );
    }
    // This level of indirection exists so that child classes can implement their own _onBlur/_onFocus
    // proxies, and still expose a "clean" overrideable onBlur/onFocus to their clients.
    protected _onBlur() {
        this.onBlur();
    }
    protected _onFocus() {
        this.onFocus();
    }
    onBlur() {}
    onFocus() {}
    getFocusContainerId() {
        return this.focusContainer.id;
    }
}

module FocusContainerWidget {}

export = FocusContainerWidget;
